export const baseURL = "https://call.vigyapan.club";
export const key = "ENTER_YOUR_KEY";






























